.Online_services_title {
    font-size: 35px;
    font-weight: bold;
    color: red;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
}

.card-grid {
    padding: 5% 10%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.card {
    background-color: var(--color-primary-light);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    padding: 4% 4%;
}

.card:hover {
    background-color: var(--color-primary);
    color: var(--color-red-dark);
}

.card-image {
    width: 100%;
    height: auto;
}

.card-content {
    padding: 16px;
}

.card-title {
    font-weight: bold;
    font-family: var(--font-telugu);
    color: red;
    font-size: 25px;
    margin: 0;
}

.card-description {
    font-family: var(--font-telugu);
    font-size: 15px;
    margin: 8px 0;
}

.card-button {
    display: inline-block;
    padding: 8px 16px;
    background-color: #f07b3f;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    border: 0px;
}

.card-button:hover {
    background-color: #e75f1b;
    color: white;
}

.Confirmdetails {
    height: 500px;
    margin: 2% 0%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.ConfirmDetails_title {
    font-size: 28px;
    font-weight: bold;
    color: red;
    text-align: center;
}

.ConfirmDetails_Card {
    border: 2px solid black;
    border-radius: 10px;
    font-size: 17px;
    margin: 0% 20%;
    padding: 3% 5%;
    height: 350px;
    width: 750px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.edit_icon {
    position: relative;
    top: -140px;
    right: -220px;
    height: 30px;
    width: 30px;
    cursor: pointer;
}
@media screen and (max-width:960px) {
    .ConfirmDetails_Card {
        /* display: grid;
        grid-template-columns: 1fr;
        grid-gap: 25px; */
        max-width: 80vw;
        margin: 0;
    }
    
}
@media screen and (max-width:676px) {
    .card-grid {
        padding: 5% 10%;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
    .card-button{
        transform: scale(0.8);
        width: 130px;
        margin: 0 25%;
    }
    .input_box {
        max-width: 95vw;
    }
    .edit_icon{
        position: relative;
        top: -300px;
    }
}