.donations,
.Yekadasa_rudratailabishekam,
.Mahanyasapurvaka,
.Sanijapam,
.Sani_santhi {
    margin: 3% 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.donations_title,
.Yekadasa_rudratailabishekam_title,
.Mahanyasapurvaka_title,
.sanijapam_title,
.sani_santhi_title {
    font-family: var(--font-telugu);
    font-size: 35px;
    font-weight: bold;
    color: red;
}

.donations_form {
    padding: 2% 20%;
    margin: 0% 0%;
    display: grid;
    grid-template-columns: 5fr 5fr;
    /* grid-template-columns: repeat(auto-fill, minmax(180px, 200px)); */
    gap: 40px;
    align-items: center;
    justify-content: center;
}

.donations_form input {
    height: 40px;
    width: 400px;
}

.donations_form select {
    height: 40px;
    width: 400px;
}
@media  screen and (max-width:860px) {
    .donations_form {
    grid-template-columns: 1fr;
    }
}
@media  screen and (max-width:500px) {
    .input_box {
        max-width: 95vw;
    }
}