.Donate-section {
    height: 510px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin: 60px 0px;
}

.updates-section {
    height: 400px;
    display: grid;
    grid-template-columns: 4fr 2fr;
    align-items: center;
    margin: 50px 50px;
}

@media screen and (max-width: 1200px) {
    .updates-section {
        display: flex;
        flex-direction: column;
        height: fit-content;
        gap: 40px;
    }
    .Donate-section {
        height: fit-content;
        width: 98%;
        margin: 0px 5px;
    }
}