.payment-container{
    display: flex;
    /* align-items: center; */
    justify-content: center;
    margin: 50px 0;
    flex-wrap: wrap;
    
}
.payment-heading{
    text-align: center;
    font-size: 2.2rem;
    margin: 20px 0;
    font-weight: bold;
    color: var(--color-red);
}
.button-payment{
width: 100%;
}
.input-payment{
    width: 100%;
}
.payment-content{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    
}
.paymentSuccess-card{
    text-align: center;
    font-size: 1.5rem;
    max-width: 500px;
    /* background-color: var(--color-success-green); */
    color: var(--color-success-green);
    /* width: max-content; */
    min-width: 85vw;
}
.paymentSucess-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
}
.paymentSuccess-button{
    margin: 5px;
}
.payment-loading-container{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgb(0, 0, 0,0.6);
    z-index: 500;
}
.payment-loading{
    position: fixed;
    right: 50%;
    bottom: 50%;
}
@media  screen and (max-width:700px) {
    .paymentQR{
        margin-bottom: 50px;
    }
    .input-payment{
        margin-bottom: 20px;
    }
}