        @import '../../Themes/AppThemes.css';
        .Baner {
            background: linear-gradient( to right, rgb(244, 51, 51), #eb0a46, #901967, #901967, #eb0a46, rgb(244, 51, 51));
            padding: 0% 0.7%;
            display: grid;
            grid-template-columns: 1fr 4fr 1fr;
            align-items: center;
        }
        
        .prevent-select {
            -webkit-user-select: none;
            /* Safari */
            -ms-user-select: none;
            /* IE 10 and IE 11 */
            user-select: none;
            /* Standard syntax */
        }
        
        .select-disable {
            pointer-events: none
        }
        
        .decoration {
            height: 30px;
            width: 100%;
            overflow: hidden;
            background: linear-gradient( to right, rgb(250, 22, 79), #eb0a46, #901967, #901967, #eb0a46, rgb(250, 22, 79));
        }
        
        .garland-img {
            height: 30px;
            width: 20%;
        }
        
        .godimg {
            height: 200px;
            width: 380px;
        }
        
        .title {
            text-align: center;
            /* width: 70%; */
            /* display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center; */
            /* gap: 25px; */
        }
        
        .govt-title {
            margin: 0 0;
        }
        
        .temple-title {
            margin-bottom: 5px;
        }
        
        .address-title {
            margin-bottom: 5px;
        }
        
        .telugu-temple-title {
            color: #ffed00;
            font-family: var(--font-telugu);
            font-size: 50px;
            font-weight: bold;
            line-height: normal;
        }
        
        .telugu-address-title {
            font-size: 18px;
            font-weight: 700;
            font-family: var(--font-telugu);
            color: white;
        }
        
        .english-temple-title {
            font-size: 36px;
            color: #ffed00;
            font-family: var(--font-english);
            font-weight: bold;
        }
        
        .english-address-title {
            font-size: 14px;
            font-weight: bold;
            color: white;
            font-family: var(--font-english);
        }
        
        .govt-logo {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        
        .govtlogo {
            height: 120px;
            width: 120px;
        }
        
        .telugu-govt-title {
            font-size: 8.5px;
            color: white;
            font-family: 'Tiro Telugu', serif;
            font-weight: 600;
        }
        
        .english-govt-title {
            font-size: 12px;
            font-weight: bold;
            color: white;
            font-family: var(--font-english);
        }
        
        @media screen and (max-width: 1440px) {
            .godimg {
                width: 300px;
                height: 200px;
            }
        }
        /* Small size laptop */
        
        @media screen and (min-width:862px)and (max-width: 1366px) {
            .godimg {
                width: 250px;
                height: 150px;
            }
            .govtlogo {
                width: 100px;
            }
            .telugu-temple-title {
                font-size: 35px;
            }
            .english-temple-title {
                font-size: 25px;
            }
            .telugu-address-title {
                font-size: 15px;
            }
            .english-address-title {
                font-size: 12px;
            }
            .telugu-govt-title {
                font-size: 7px;
            }
            .english-govt-title {
                font-size: 8px;
            }
        }
        /* Tablet */
        
        @media screen and (max-width: 862px) {
            .godimg {
                margin-left: 100px;
                grid-column: 1 / 2;
                grid-row: 1 / 2;
                /* grid-area: godimg; */
            }
            .title {
                width: 100%;
                padding: 0px;
                margin: 0px;
                grid-column: 1 / 3;
                grid-row: 2 / 3;
                /* grid-area: templetitle; */
            }
            .telugu-temple-title {
                font-size: 35px;
            }
            .english-temple-title {
                font-size: 25px;
            }
            .govt-logo {
                grid-column: 2 / 3;
                grid-row: 1 / 2;
                /* grid-area: govtlogo; */
            }
            .Baner {
                display: grid;
                /* grid-template-areas: 'godimg . govtlogo' 'templetitle templetitle'; */
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr 1fr;
            }
        }
        /* Mobile */
        
        @media screen and (max-width: 500px) {
            .Baner {
                width: 100%;
                height: 50vh;
                overflow: hidden;
            }
            .godimg {
                width: 180px;
                height: 135px;
                margin-left: 30px;
            }
            .govtlogo {
                width: 100px;
                height: 100px;
            }
            .title{
                transform: scale(0.8);
            }
        }
        @media screen and (max-width: 500px) {
            .godimg,.govt-logo,.title {
                transform: scale(0.7);
            }
            .title{
                translate: 0 -48px;
            }
        }