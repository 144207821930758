:root {
    --color-primary: #FFD460;
    --color-primary-light: #ffe6a2;
    /* --color-primary-dark: #ffc830; */
    --color-primary-dark: #e8b11b8e;
    --color-secondary: #F07B3F;
    --color-secondary-dark: #dc6f38;
    --color-secondary-light: #fecfb8;
    --color-red: #EA5455;
    --color-red-dark: rgb(200, 74, 74);
    --color-red-youtube-light: #ff7575;
    --color-red-youtube: #ff2d2d;
    --color-red-youtube-dark: #cc2020;
    --color-dark: #2D4059;
    --color-success-green: #4bb543;
    /* --font-telugu: 'Tiro Telugu', serif; */
    --font-english: 'Roboto Slab', serif;
    --color-red-dark: rgb(200, 74, 74);
    /* --color-red-youtube-light:#ff7575;
--color-red-youtube:#ff2d2d;
--color-red-youtube-dark:#cc2020; */
    --color-blue-youtube: #2F3185;
    --color-blue-youtube-light: #333485;
    --color-orange: #F58220;
    --color-orange-dark: #DE6B08;
    /* --color-dark: #2D4059; */
    --color-purple: #d51e2ac0;
    --font-telugu: 'Noto Serif Telugu', serif;
}