.youtube-container {
    background-color: var(--color-blue-youtube);
    background-image: url("../../Assets/Images/mandeshwarswamylogo-low.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: right;
    background-blend-mode: soft-light;
    color: white;
    display: flex;
    height: 400px;
    margin: 10px 0;
    padding: 50px 0;
    padding-left: 100px;
    z-index: -1;
}

.youtube-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 80px;
}

.youtube-heading {
    font-family: var(--font-english);
    margin-bottom: 20px;
    font-size: 1.6rem;
    font-weight: 700;
}

.youtube-description {
    font-family: var(--font-telugu);
    font-size: 1.2rem;
}

.youtube-button {
    border: 0;
    width: auto;
    height: 15%;
    border-radius: 5px;
    margin: 10px 32%;
    color: white;
    font-family: var(--font-english);
    background-color: var(--color-secondary);
    font-weight: 700;
}

.youtube-button:hover {
    background-color: var(--color-secondary-dark);
}

.youtube-button:active {
    background-color: var(--color-secondary-light);
    color: var(--color-dark);
}

@media screen and (max-width: 1352px) {
    .youtube-container {
        height: 700px;
        display: flex;
        flex-direction: row;
        text-align: justify;
        justify-content: center;
        flex-wrap: wrap;
        padding: 25px;
        margin: 0px;
        background-position-y: bottom;
    }
    .youtube-heading {
        text-align: center;
    }
    .youtube-embedded {
        height: 250px;
        margin-top: 10px;
    }
    .youtube-text {
        height: 400px;
        display: flex;
        justify-content: space-evenly;
    }
}

@media screen and (max-width: 500px) {
    .youtube-container {
        height: 780px;
    }
    .youtube-heading {
        font-size: 1.3rem;
    }
    .youtube-embedded iframe {
        width: 330px;
        height: 230px;
    }
    .youtube-description {
        font-size: 1rem;
        width: 270px;
    }
    .youtube-button {
        width: 120px;
        padding: 13px 5px;
    }
    iframe{
        max-width: 99vw;
    }
}