.export-container{
    min-height: 60vh;
    width: 100%;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly; */
}
.export-heading{
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--color-red);
}
.export-inputs{
    height: 40px;
    width: 200px;
    cursor: pointer;
}
.export-submit{
    width: 200px;
    position: relative;
    right: -120px;
}
.payment-loading-container{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgb(0, 0, 0,0.6);
    z-index: 500;
}
.payment-loading{
    position: fixed;
    right: 50%;
    bottom: 50%;
}
#export-inputs-checkbox{
    margin: 10px 5px;
    cursor: pointer;
}
.export-layout{
    display: grid;
    grid-template-columns: 210px 210px;
    gap: 30px;
    margin: 2% 38%;
}