.Navbar {
    background-color: var(--color-orange);
    height: 45px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* padding: 0% 10%; */
    position: sticky;
    top: 0;
    z-index: 3;
}

.nav-item {
    padding: 12px 20px;
    text-align: center;
    height: 100%;
    cursor: pointer;
}

.nav-item>h6 {
    font-weight: bold;
}

.nav-item:hover {
    background-color: var(--color-orange-dark);
}

.mobileMenu{ 
    width: 50px;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: -1000;
    background-color: white;
    border-radius: 50%;
    border:2px solid white;
    cursor: pointer;
    opacity: 0;
}
/* .nav-signup {} */
/* @media screen and (min-wdth:1020px) {
    .mobileMenu{ display: none;}
} */
/*small screen laptop*/
@media screen and  (max-width: 1366px) and (orientation:portrait) {
    .nav-item>h6{
        font-size: 0.87rem;
    }
}

/* Tablet */
@media screen and (max-width: 1020px)and (orientation:portrait) {
    .Navbar {
        display: none;
    }
    .mobileMenu{
        z-index: 1000;
       opacity: 1;
    }
}
.login-header{
 font-weight: bold;
 color: var(--color-orange);  
}
.card-button{
    width: 30%;
    margin: 5px 35%;
}
.login-feilds{
    display: flex;
    flex-direction: column;
    padding: 10px 80px;
    
}
.login-inputField{
    height: 40px;
    margin-bottom: 25px;
}
.login-errors{
    display: flex;
    justify-content: center;
    background-color: var(--color-red);
    color: white;
    margin: 0 80px;
    padding: 5px 0;
    border: 0;
    border-radius: 8px;
}
.payment-loading-container{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgb(0, 0, 0,0.6);
    z-index: 500;
}
.payment-loading{
    position: fixed;
    right: 50%;
    bottom: 50%;
}
.menu-body{
    color: var(--color-orange);
}
.menu-header{
    color: white;
    background-color: var(--color-orange);
}
.nav-menu-item{
    margin: 15%;
    text-align: center;
    cursor: pointer;
}