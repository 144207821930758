.dashboard-container{
    margin: 1% 15%;
    display: flex;
}
.dashboard-menu{
    height: 50vh;
    background-color: var(--color-primary-light);
    padding: 20px;
}
.dashboard-contents{
    /* background-color: rgb(242, 141, 233); */
    padding: 0 15px;
}
.dashboard-contents-header{
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px var(--color-red) solid;
}
.dashboard-contents-actions{
    display: flex;
}
.dashboard-actions{
    margin: 5px 10px;
    cursor: pointer;
}

.dashboard-contents-heading{
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--color-red);
}

.dashboard-cells{
    color: var(--color-red);
    border: 0;
    min-width: 120px;
}
.dashboard-table-cells{
    text-align: center;
}
.table-amount{
    text-align: right;
}
.action-icon{
    width: 25px;
}
.table-details{
    cursor: pointer;
}
.dashboard-pagination{
    margin: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dashboard-table{
    max-height: 70vh;
    overflow-y: scroll;
}
/* .dashboard-contents-body{
    
} */
.payment-loading-container{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgb(0, 0, 0,0.6);
    z-index: 500;
}
.payment-loading{
    position: fixed;
    right: 50%;
    bottom: 50%;
}