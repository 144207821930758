.Annadanam {
    height: 100%;
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    border-radius: 5px;
    font-family: var(--font-telugu);
    overflow: hidden;
    background-color: #FACDA6;
}

.Annadanam-text {
    height: 180px;
    width: 500px;
    /* overflow-y: scroll; */
    padding: 20px 0;
}

.anndanam-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.annadanam-button {
    border: 0;
    border-radius: 5px;
    width: 160px;
    height: 35px;
    background-color: var(--color-secondary);
    color: white;
    font-family: var(--font-english);
    padding-bottom: 5px;
    font-weight: 600;
    margin: 10px;
    margin-top: 25px;
}

.annadanam-button:hover {
    background-color: var(--color-secondary-dark);
}

.annadanam-button:active {
    background-color: var(--color-secondary-light);
}

@media screen and (max-width: 768px) {
    .Annadanam {
        height: 460px;
        margin-bottom: 20px;
    }
}

@media screen  and (max-width: 500px) {
    .Annadanam {
        height: 520px;
    }
    .Annadanam-text {
        width: 350px;
        max-width: 99vw;
    }
    .Annadanam-img img{
        max-width: 99vw;
    }
}