.Events {
    font-family: var(--font-english);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background-color: var(--color-primary-light);
    padding: 0 20px ;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    
}

.Events-title {
    font-size: 18px;
    font-weight: bold;
    /* color: white; */
    margin-top: 20px;
}

.Events-ul {
    height: 331px;
    overflow-y: scroll;
    /* background-color: var(--color-primary-light);
    border-radius: 10px;
    padding: 20px 40px; */
}

.Events-ul>li {
    margin: 20px 0px;
}