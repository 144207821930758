.footer-container {
  background-color: var(--color-dark);
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  padding: 25px 25px;
  font-family: var(--font-english);
}

.footer-image {
  margin: 0 15px;
}

.contactus-heading {
  text-decoration: none;
  color: white;
  font-size: 1.3rem;
  font-weight: 500;
}

.contactus-item {
  text-decoration: none;
  color: white;
  font-size: 0.9rem;
  cursor: pointer;
}

.footer-right-item {
  text-decoration: none;
  color: white;
  cursor: pointer;
  font-size: 0.9rem;
}

.footer-left-item {
  margin-bottom: 62px;
  font-size: 1.5rem;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .footer-left {
    width: 400px;
  }
  .footer-right {
    width: 180px;
  }
}

@media screen and (max-width: 1200px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: fit-content;
    width: 100%;
    padding: 2%;
    margin: 0px 0px;
  }
  .footer-right {
    /* margin-right: 210px; */
  }
  .footer-mid {
    /* margin-left: 20px; */
  }
  .footer-left {
    /* margin-left: 20px; */
  }
}
@media screen and (max-width: 500px) {
  .footer-left {
    max-width: 90vw;
  }
}
