.Newsupdates {
    font-family: var(--font-telugu);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background-color: var(--color-secondary-light);
    padding: 20px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.Newsupdates-title {
    font-size: 18px;
    font-weight: bold;
    /* color: white; */
}

.Newsupdates-ul {
    height: 331px;
    overflow-y: scroll;
    /* background-color: var(--color-secondary-light); */
    /* border-radius: 10px; */
    /* padding: 20px 40px; */
}

.Newsupdates-ul>li {
    font-size: 15px;
    margin: 0px 0px;
}