.scroll-left {
    height: 50px;
    overflow: hidden;
    font-weight: bold;
    position: relative;
    background-color: var(--color-primary);
    color: black;
}

.scroll-left p {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0px;
    line-height: 50px;
    text-align: center;
    /* Starting position */
    transform: translateX(100%);
    /* Apply animation to this element */
    animation: scroll-left 25s linear infinite;
}


/* Move it (define the animation) */

@keyframes scroll-left {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}