.Donations {
    height: 100%;
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-evenly; */
    border-radius: 5px;
    font-family: var(--font-telugu);
    overflow: hidden;
    background-color: #FACDA6;
}

.Donations-text {
    height: 180px;
    width: 500px;
    /* overflow-y: scroll; */
    padding: 20px 0;
}

.donations-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.donations-button {
    border: 0;
    border-radius: 5px;
    width: 160px;
    height: 35px;
    background-color: var(--color-secondary);
    color: white;
    font-family: var(--font-english);
    padding-bottom: 5px;
    font-weight: 600;
    margin: 10px;
}

.donations-button:hover {
    background-color: var(--color-secondary-dark);
}

.donations-button:active {
    background-color: var(--color-secondary-light);
}

@media screen and (max-width: 768px) {
    .Donations {
        height: 470px;
    }
}

@media screen and (max-width: 500px) {
    .Donations {
        height: 470px;
    }
    .Donations-text {
        width: 350px;
        max-width: 99vw;
    }
    .Donations-img img{
        max-width: 99vw;
        
    }
}